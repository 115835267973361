//------------------------------------------------------//
// Slide menu 🎛️ toggle
//------------------------------------------------------//
const navigationSlideToggle = document.querySelector('#navigation-slide-toggle');
const navigationSlide = document.querySelector('#navigation-slide');
const body = document.querySelector('body');
// Animation

const timelineMenu = gsap.timeline();

timelineMenu.to(navigationSlide, {
  duration: 0.05,
  y: 0,
})
  .to(navigationSlideToggle.querySelector('.icon span.one'), { // Query the specific classes based on their parent
    duration: 0.1,
    y: 6
  })
  .to(navigationSlideToggle.querySelector('.icon span.three'), {
    duration: 0.1,
    y: -6
  }, '<')
  .set(navigationSlideToggle.querySelector('.icon span.two'), {
    autoAlpha: 0
  })
  .to(navigationSlideToggle.querySelector('.icon span.one'), {
    duration: 0.1,
    rotation: 45,
    transformOrigin: "center center"
  }, '<')
  .to(navigationSlideToggle.querySelector('.icon span.three'), {
    duration: 0.1,
    rotation: -45,
    transformOrigin: "center center"
  }, '<')
  // .add(() => { }, "+=0.25") // ⏱️ Add time delay before next animation
  .from(navigationSlide.querySelectorAll('.alloy-container section'), {
    // duration: 1,
    x: -100,
    opacity: 0,
    // ease: "none",
    stagger: {
      amount: 0.05
    }
  });

// Geen idee wat deze code doet maar is belangrijk
timelineMenu.reversed(true);

// Functie die de animatie afspeeld of reversed
function animateMenu() {
  if (timelineMenu.reversed()) {
    timelineMenu.reversed(!timelineMenu.reversed()).timeScale(1); // Speel aimatie
  } else {
    timelineMenu.timeScale(3).reversed(!timelineMenu.reversed()); // Reverse animatie en speel 3x zo snel af
  }
}
// On 🐭 click add class to body and toggle 🦻 ARIA lables
navigationSlideToggle.addEventListener('click', () => {
  body.classList.toggle('showNavigationSlide');
  navigationSlideToggle.setAttribute('aria-expanded', navigationSlideToggle.getAttribute('aria-expanded') == 'true' ? 'false' : 'true');
  animateMenu();
});

// END Slide menu 🎛️ toggle  -------------------------------------//

//------------------------------------------------------//
// On browser 📜 scroll full window height
//------------------------------------------------------//
window.onscroll = function (ev) {
  if (window.scrollY >= 250) {
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    body.classList.add('showScrolled');
  } else {
    body.classList.remove('showScrolled');
  }
};
// END On browser 📜 scroll full window height  -------------------------------------//


//------------------------------------------------------//
// FAQ toggle
//------------------------------------------------------//
function animateFAQ(timeline) {
  if (timeline.reversed()) {
    timeline.reversed(!timeline.reversed()).timeScale(1); // Speel aimatie
  } else {
    timeline.timeScale(1.5).reversed(!timeline.reversed()); // Reverse animatie en speel 3x zo snel af
  }
}
const containerFaq = document.querySelectorAll('.overview-faq');

containerFaq.forEach(container => {

  const items = container.querySelectorAll('.alloy-item');

  items.forEach((item, index) => {
    // Create a timeline for each item
    const timeline = gsap.timeline();
    timeline
      .set(item.querySelector('.content'), { autoAlpha: 1 })
      .from(item.querySelector('.content'), {
        duration: 0.2,
        // scaleY: 0,
        height: 0,
        transformOrigin: "top"
      })
    // Set the reversed status to true, this is for the function animateFAQ()
    timeline.reversed(true);
    //------------------------------------------------------//
    // Toggle the first item open on page load
    //------------------------------------------------------//
    if (index === 0) {
      item.classList.toggle('closed');
      animateFAQ(timeline);
    }
    // END Toggle the first item open -------------------------------------//
    //------------------------------------------------------//
    // On click animate FAQ
    //------------------------------------------------------//
    item.addEventListener('click', e => {
      item.classList.toggle('closed');
      animateFAQ(timeline);
    })
    // END On click animate FAQ  -------------------------------------//
  });
});



// END FAQ toggle -------------------------------------//