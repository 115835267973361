import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, MorphSVGPlugin);
window.gsap = gsap;
window.MorphSVGPlugin = MorphSVGPlugin;
ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//
//------------------------------------------------------//
// Data 
//------------------------------------------------------//
const mediaQuery = window.matchMedia('(min-width: 992px)');

document.querySelectorAll(".ACF-data").forEach(function (container) {
  // Place items on blob
  if (mediaQuery.matches) {
    // Then trigger an alert
    const items = container.querySelectorAll(".small-numbers .item");
    gsap.set(items, {
      motionPath: {
        path: ".blob",
        align: ".blob",
        end: function (index, target, targets) { //function-based value
          return 0.2 * index - 0.29;
        },
        autoRotate: false,
        alignOrigin: [0, 0.2]
      }
    });
  }

  // 🧮 Animate numbers
  const spans = container.querySelectorAll(".number span");

  gsap.from(spans, {
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: "top center", // Can be top, center, bottom 
      end: "65% center", // Can be top, center, bottom 
      // pin: true,
    },
    textContent: 0,
    duration: 1,
    ease: "power1.in",
    snap: { textContent: 1 },
    stagger: {
      each: 1.0,
      onUpdate: function () {
        this.targets()[0].innerHTML = numberWithCommas(
          Math.ceil(this.targets()[0].textContent)
        );
      }
    }
  });
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
});
// END Data -------------------------------------//

